import React from "react";
import classNames from 'classnames';

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import ReactMarkdown from 'react-markdown';

import HeaderImage from '../components/imageHeader';

import Layout from "../components/layout";

import '../styles/index.css';
import styles from '../styles/consulting.module.css';
// import { FaCheckCircle } from "react-icons/fa";

const ConsultoriaPage = ({ data }) => {
    data = data.allStrapiConsultoriaPme.edges[0].node;

    const intl = useIntl();

    const texto = intl.locale === 'en' && data.textoEn ? data.textoEn : data.textoPt;

    return (
        <Layout titlePage={intl.messages['consultoria.title']}>

            <HeaderImage title={intl.messages['consultoria.title']} />

            <div className={classNames("container", styles.consultingContainer)}>
                <main>

                    <ReactMarkdown
                        className={styles.boxServicoImg}
                        source={texto}
                        transformImageUri={
                          uri => uri.startsWith('http') ?
                            uri : `${process.env.GATSBY_API_URL}${uri}`
                        }
                    />

                    {/* <section className={styles.divHeaderText}>
                        <h4 className={styles.title}>CONSULTORIA PME – ESTRATÉGIA EMPRESARIAL E FINANÇAS CORPORATIVAS</h4>

                        <p className={styles.description}>A GlobalTrevo Consulting possui forte atuação em <b>consultoria para pequenas e médias empresas (Consultoria PME)</b>, trabalhando em diversos segmentos, como: indústrias, educação, TI, telecom, alimentos, saúde e serviços. A partir dos nossos escritórios, temos capacidade de atendimento a todo o Brasil.</p>
                    </section>

                    <section className={styles.divMainText}>
                        <h4 className={styles.title}>NOSSOS SERVIÇOS DE CONSULTORIA PME SÃO:</h4>

                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Avaliação de Empresas (Valuation)</p>
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Planos de Negócios (Business Plan)</p>
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Pesquisa de Mercado</p>
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Consultoria para projetos no BNDES</p>
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Plano de Reestruturação Empresarial</p>
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Assessoria para venda de empresas</p>

                        <p className={styles.description} style={{marginTop: 50}}>Temos equipe com capacidade de atendimento nacional, que contam com metodologias desenvolvidas internamente ou utilizadas mundialmente para melhoria de performance e ampliação de resultados de nossos clientes. Fale com nosso departamento comercial para uma proposta.</p>
                    </section>

                    <section className={styles.divMainText}>
                        <h4 className={styles.title}>LOCAIS ONDE JÁ TIVEMOS PROJETOS DE CONSULTORIA PME</h4>

                        <figure className={styles.posFig}>
                            <img src={'https://global-cms.doois.com.br/uploads/atendimento_nacional_600x460_3c2d5406d8.png'} alt=""/>
                        </figure>
                    </section> */}
                </main>

                {/* Link do Whatsapp */}
                <div className="whatsAppLogo">
                        <a href="https://wa.me/5511976282854" title="Whatsapp" className='wppFixed' >
                            <img src="/wpp.svg" alt="" />
                        </a>
                    </div>

            </div>
        </Layout>
    )
}

export const query = graphql`
query{
    allStrapiConsultoriaPme {
        edges {
            node {
                textoPt
                textoEn
            }
        }
    }
}
`

export default ConsultoriaPage;
